import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

function App() {
  const spec = require('./zapApi_0.3.2-beta.json')
  console.log("spec", spec)
  return (
    <div className="App">
      <SwaggerUI spec={spec} />
    </div>
  );
}

export default App;


